import { requestPasswordReset } from '@/lib/Api';
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid';
import { Fragment, useState } from 'react'
import { TailSpin } from 'react-loading-icons';
import { toast } from 'react-toastify';

export default function PasswordReset({isOpen, setIsOpen}) {
    const [email, setEmail] = useState('');

    const [requestingReset, setRequestingReset] = useState(false);
    const onSubmit = (e) => {
        e.preventDefault();
        setRequestingReset(true);
        requestPasswordReset({
            email: email
        }).then(response => {
            if(response.data.success) {
                toast.success(`A password reset link has been sent to ${email}.`)
                setIsOpen(false);
                setEmail('');
                setRequestingReset(false);
            } else if(response.data.error) {
                toast.error(response.data.error);
                setIsOpen(false);
                setRequestingReset(false);
            } else {
                toast.error('There was an error requesting your password reset. Please ensure you have entered the correct email.')
                setRequestingReset(false);
            }
        }).catch(error => {
            toast.error('There was an error requesting your password reset.');
            setRequestingReset(false);
        })
    }
    return (
    <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => {}}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
                </Transition.Child>   

                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <div className="inline-block align-bottom  px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="bg-white relative  shadow-xl shadow sm:rounded-lg">
                    <XIcon onClick={() => setIsOpen(false)} className="absolute h-6 top-3 right-3 text-gray-500 hover:text-black cursor-pointer"></XIcon>

                    <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Reset Your Password</h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>To reset your password enter your email address associated with your account below.</p>
                    </div>
                    <form onSubmit={(e) => onSubmit(e)} className="mt-5 sm:flex sm:items-center">
                        <div className="w-full sm:max-w-xs">
                        <label htmlFor="email" className="sr-only">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            className="shadow-sm text-black border-gray-200 border p-3 outline-primary focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="you@example.com"
                        />
                        </div>
                        <button
                        type="submit"
                        disabled={requestingReset}
                        className="mt-3 disabled:bg-primary-800 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                        {!requestingReset && <span>Request Password Reset</span>}
                        {requestingReset && <TailSpin className="h-6"></TailSpin>}
                        </button>
                    </form>
                    </div>
                </div>
                </div>
                </Transition.Child>
            </div>
        </Dialog>
    </Transition.Root>
    )
  }