import Head from 'next/head';
type Props = {
    children: JSX.Element,
};


export default function Layout({children} : Props) {
    return (
        <>
            <main className="h-full bg-gray-900">
                {children}
            </main>
        </>
    )
}