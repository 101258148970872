import type { NextPage } from 'next'
import Head from 'next/head'
import { useEffect, useState } from 'react';
import Layout from '../components/LoginLayout';
import { useRouter } from 'next/router'
import { checkLogin, removeJWT, setJWT } from '../lib/Auth';
import { refreshToken } from '../lib/Api';
import { TailSpin } from 'react-loading-icons';

import { serialize } from 'cookie';
import PasswordReset from '@/components/PasswordReset';
import { toast, ToastContainer } from 'react-toastify';
import { ExclamationIcon } from '@heroicons/react/solid';


const Login: NextPage = ({platCode, apiOffline}) => {
    const router = useRouter()
    const [loggingIn, setLoggingIn] = useState(false);

    const [passwordResetOpen, setPasswordResetOpen] = useState(false);
    

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        rememberMe: false,
    })

    const [formErrors, setFormErrors] = useState({
        email: [],
        password: []
    })

    const { email, password, rememberMe } = formData;

    const onChange = e => {
        setFormData({... formData, [e.target.name] : e.target.value})
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoggingIn(true);
        try {
            const resp = await checkLogin(email, password, rememberMe);
            if(resp.data && resp.data.success == true) {
                setJWT(resp.data.token);

                if(platCode) {
                  router.push(`/upgrade/platinum?code=${platCode}`);
                  setLoggingIn(false);
                } else {
                  router.push('/');
                  setLoggingIn(false);
                }
               
            } else if(resp.data.message) {
              toast.error(resp.data.message);
              setLoggingIn(false);
            }
        } catch (err) {
            setLoggingIn(false);
        }
       
        // checkLogin(email, password, rememberMe).then(response => {
        //     console.log(response.data);
        //     setJWT(response.data.accessToken);
        // }).catch(error => {
        //     if(error.response && error.response.data) {
        //         setFormErrors(error.response.data);
        //     }
        // })
    }
    

    return <Layout>
    <Head>
      <title>Defyre Society - Login</title>
      <link rel="icon" href="/fav_ico.png" />
    </Head>
    <PasswordReset isOpen={passwordResetOpen} setIsOpen={setPasswordResetOpen}></PasswordReset>
    <ToastContainer theme="dark"></ToastContainer>
    <div className="min-h-full flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img
              className="h-12 w-auto"
              src="images/defyre-logo-new.png"
              alt="Defyre Society"
            />
            <h2 className="mt-6 text-3xl font-extrabold text-white">Sign in to your account</h2>
            <p className="mt-2 text-sm text-gray-500">
              Or{' '}
              <a href="https://defyresociety.com" className="font-medium text-primary-600 hover:text-primary-500">
                start your 10-day free trial
              </a>
            </p>
          </div>
          {apiOffline && <div className="px-5">
            <div className="bg-red-50 border-l-4 mt-5 border-red-400 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">
                    Defyre Society is temporarily offline. Please try again later.
                  </p>
                </div>
              </div>
          </div>
        </div>}
          <div className="mt-8 bg-white lg:bg-transparent px-5 py-8 rounded text-black lg:text-white">         
            <div className="">
              <form onSubmit={onSubmit} method="POST" className="space-y-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-black lg:text-white">
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={onChange}
                      autoComplete="email"
                      required
                      className="appearance-none !text-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-black lg:text-white">
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={onChange}
                      autoComplete="current-password"
                      required
                      className="appearance-none block !text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-black lg:text-white">
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm">
                    <a onClick={() => setPasswordResetOpen(true)} className="font-medium cursor-pointer text-primary-600 hover:text-primary-500">
                      Forgot your password?
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2  focus:ring-primary-500"
                  >
                    {!loggingIn && <span>Sign in</span>}
                    {loggingIn && <TailSpin className="h-4"></TailSpin>}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1549046675-dd779977de88?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1980&q=80"
          alt=""
        />
      </div>
    </div>
  </Layout>
}

export default Login;

export async function getServerSideProps(context) {
  let token;
  const {token : queryToken, welcome, platCode} = context.query;
  if(queryToken) {
    context.res.setHeader('Set-Cookie', serialize('defyreAuth', queryToken, { path: '/' }));

    let url = '/';
    if(welcome) {
      url = '/welcome';
    }

    return {
      redirect: {
        permanent: false,
        destination: url
      }
    }
  }
  try {
    token = await refreshToken(context);

  } catch (err) {

    if(err.code == 'ECONNREFUSED') {
      return {
        props: {
          apiOffline: true
        }
      }
    }

    
    if(err.response && err?.response?.status !== 401) {
      return {
        redirect: {
          permanent: false,
          destination: "/",
        },
        props:{},
      };
    }
  }

  setJWT(token?.data?.access_token);

  return {
    props: {
      platCode: platCode ?? null
    }
  }
}